import {
    Box,
    Button,
    Flex,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Stack,
    Text,
    useToast,
} from "@chakra-ui/react"

import React, { useCallback } from "react"
import { FormProvider, useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { IUseResale } from "../../../dtos/IContact.dto"
import { useResale } from "../../../hooks/useContact"
import InputComponent from "../../forms/inputComponent"
import { States } from "../../../mocks/states"
import SelectComponent from "../../forms/select"



const schemaValidation = yup.object({
    name: yup.string().required("Nome é obrigatório"),
    email: yup
        .string()
        .required("E-mail é obrigatório")
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Digite um e-mail válido com domínio"
        ),
    telefone: yup.string().required("Telefone é obrigatório"),
    empresa: yup.string().required("Empresa é obrigatório"),
    cnpj: yup.string().required("CNPJ é obrigatório"),
    state: yup.string().required("Estado é obrigatório"),
    city: yup.string().required("Cidade é obrigatório"),
})

export const ResaleForm = () => {
    const methods = useForm<IUseResale>({
        resolver: yupResolver(schemaValidation),
    })
    const { mutateAsync: createResale, isLoading } = useResale()
    const toast = useToast({
        duration: 5000,
        isClosable: true,
        position: "bottom",
    })



    const handleSubmit = async (data: IUseResale) => {
        const response = await createResale(data)

        if (response?.data?.status === "mail_failed") {
            return toast({
                title: "Erro ao enviar e-mail",
                description:
                    "Não foi possível enviar o e-mail, tente novamente mais tarde.",
                status: "error",
            })
        }

        toast({
            title: "E-mail enviado com sucesso",
            description: "Em breve entraremos em contato.",
            status: "success",
        })


        methods.reset()
    }

    return (
        <div className="my-5">
            <FormProvider {...methods}>
                <Stack spacing={6}>
                    <InputComponent
                        name="name"
                        label="Nome"
                        placeholder="Seu nome"
                    />
                    <InputComponent
                        name="empresa"
                        label="Empresa"
                        placeholder="Nome do seu estabelecimento"
                    />

                    <InputComponent
                        name="cnpj"
                        label="CNPJ"
                        placeholder="00.000.000/0000-00"
                        mask="99.999.999/9999-99"
                    />

                    <InputComponent
                        name="email"
                        label="E-mail"
                        placeholder="exemplo@email.com"
                        type="email"
                    />
                    <InputComponent
                        name="telefone"
                        label="Telefone"
                        placeholder="Ex.: (51) 99999-9999"
                        mask="(99) 99999-9999"
                    />
                    <SimpleGrid
                        columns={{
                            base: 1,
                            md: 2,
                        }}
                        spacing={4}
                    >
                        <InputComponent
                            name="city"
                            label="Sua Cidade"
                            placeholder="Ex.: Porto Alegre"
                        />
                        <SelectComponent
                            name="state"
                            label="Seu estado"
                            placeholder="Ex.: RS"
                            options={States.map(state => ({
                                name: state.nome,
                                value: state.sigla,
                            }))}
                        />
                    </SimpleGrid>



                    <Text
                        as="span"
                        fontSize="0.75rem"
                        fontWeight="500"
                        lineHeight="125%"
                        color="#495754"
                        textAlign="center"
                    >
                        *Sua privacidade é nossa prioridade. Garantimos que suas
                        informações serão mantidas em sigilo e não serão compartilhadas
                        sem o seu consentimento.
                    </Text>
                </Stack>

                <Flex className="mt-2" justifyContent="center" alignItems="center" width="100%">
                    <Button
                        background="#025F1D"
                        mr={3}
                        onClick={methods.handleSubmit(handleSubmit)}
                        color="#fff"
                        width="23.93rem"
                        borderRadius="12.5rem"
                        _hover={{ background: "#014414c5" }}
                        isLoading={isLoading}
                    >
                        Enviar
                    </Button>
                </Flex>
            </FormProvider>
        </div>
    )
}
